import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import WebFont from 'webfontloader';

import SiteHeader from './components/SiteHeader/SiteHeader';
import './styles/index.scss';

import {
	CSSTransition, 
	TransitionGroup, 
} from 'react-transition-group';

const Home = lazy(() => import('./pages/Home/Home'));
const About = lazy(() => import('./pages/About/About'));
const Gdpr = lazy(() => import('./pages/Misc/Gdpr'));
const Contact = lazy(() => import('./pages/Contact/Contact'));
const Error = lazy(() => import('./pages/Error/Error'));

WebFont.load({
  google: {
    families: ['Quicksand:400,700', 'Playball&display=fallback']
  }
});

function AnimatedRoutes() {
  const location = useLocation();
  return (
    <TransitionGroup component={'main'} role="main" className="main transition-group">
      <CSSTransition 
        key={location.pathname}
        timeout={450}
        classNames="transition"
      >
        <section className="transition-item">
          <Suspense fallback={<div className="loading-animation"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}>
            <Routes location={location}>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/gdpr" element={<Gdpr />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<Error />} />
            </Routes>
          </Suspense>
        </section>
      </CSSTransition>
    </TransitionGroup>
  );
}

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <SiteHeader />
        <AnimatedRoutes />
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
